<template>
  <v-app>
    <v-app-bar app color="#FFFFFF" light elevation="0" height="75">
      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 "
            contain
            max-height="75"
            :src="LogoInsurpedia"
            max-width="225"
            style="margin-left: 2rem"
          />

        </div>
          </router-link>

          <v-spacer></v-spacer>
          <!-- <div class="nav_options">
            <router-link
            style="text-decoration: none"
            :to="{ path: '/', hash: '#seguros' }"
          >
            <v-btn text>
              <span class="mr-2">Seguros</span>
            </v-btn>
          </router-link>

          <router-link style="text-decoration: none" :to="{ path: '/cotizador' }">
            <v-btn text>
              <span class="mr-2">Cotizador</span>
            </v-btn>
          </router-link>

          <router-link
            style="text-decoration: none"
            :to="{ path: '/', hash: '#nosotros' }"
          >
            <v-btn text>
              <span class="mr-2">Nosotros</span>
            </v-btn>
          </router-link>

          <router-link
            style="text-decoration: none"
            :to="{ path: '/', hash: '#contacto' }"
          >
            <v-btn text>
              <span class="mr-2">Contacto</span>
            </v-btn>
          </router-link>

          <router-link
            style="text-decoration: none"
            :to="{ path: '/', hash: '#mis-seguros' }"
          >
            <v-btn text>
              <span class="mr-2">Mis seguros</span>
            </v-btn>
          </router-link>
          </div> -->

      
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LogoInsurpedia from "@/assets/images/logoinsurpedia.png";

export default {
  components: {
    Footer,
  },
  data: () => ({
    LogoInsurpedia,
  }),
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .nav_options{
    display: none;

  }
}
</style>