<template>
  <div style="background-color: #223e92">
    <div style="background-color: #41b0e7; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div
          class="footer-element"
          style="min-width: 200px"
        >
          <v-img
            class="shrink mt-1 "
            contain
            max-height="100"
            :src="LogoGallagherLight"
            max-width="175"
          />
        </div>

        <div
          class="footer-element"
          style="min-width: 300px"
        >
          <div style="height: 100%">
            <div style="margin-bottom: 1.5rem">
              <p>Dirección</p>
              <p>Lo Barnechea, Chile</p>
            </div>

     

            <div style="margin-bottom: 1.5rem">
              <p>
                Teléfono:
                <a style="color: #41b0e7" href="tel:+569 61280760">+569 61280760</a>
              </p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Email:
                <a style="color: #41b0e7" mailto="info@insurpedia.com">info@insurpedia.com</a>
              </p>
            </div>
          </div>
        </div>
        <div class="footer-element" style="min-width: 150px">
          <v-col class="ma-0 pa-0 rrss">
            <div style="height: 100%">Síguenos en</div>
            <v-row class="ma-0 pa-0 rrss_icons">
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.instagram.com/insurpedia/"
                target="_blank"
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.facebook.com/Insurpedia/"
                target="_blank"
              >
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.linkedin.com/company/insurpedia/about/"
                target="_blank"
              >
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
            </v-row>
             <br>
              Este riesgo es tomado por         
              <v-img
            class="shrink mt-1 "
            contain
            max-height="100"
            :src="LogoSB"
            max-width="175"
          />
          </v-col>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LogoGallagherLight from "@/assets/images/logoinsurpedia_footer.png";
import LogoSB from "@/assets/images/SB_logo.png";

export default {
  name: "Footer",
  data: () => ({
    LogoGallagherLight,
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-element {
  color: white;
  min-height: 200px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
}

.layout.wrap {
  justify-content: space-around;
}

@media (max-width: 768px) {
  
  .layout, .row, .wrap{
    flex-direction: column;
    align-items: center;
    .footer-element{
      align-items: center;
      flex-direction: column;
      &:first-child{
        min-height: unset;
      }
      div{
        display: flex;
        align-items: center;
        flex-direction: column;
        div{
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }

      .rrss{
        height: unset;
        .rrss_icons{
          flex-direction: row;
        }
      }
    }
  }
}
</style>
